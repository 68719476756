import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  createCourse({
    name,
    sellingPrice,
    effectiveDurationSeconds,
    vendibleTaggingsAttributes = [],
    passiveCourseExtensionsAttributes = [],
  }) {
    const formData = serialize(snakecaseKeys({
      course: {
        name,
        sellingPrice,
        effectiveDurationSeconds,
        vendibleTaggingsAttributes,
        passiveCourseExtensionsAttributes,
      },
    }))

    return axiosIns.post('/waiter/courses', formData)
  },
  updateCourse({
    id,
    name,
    sellingPrice,
    effectiveDurationSeconds,
    vendibleTaggingsAttributes = [],
    passiveCourseExtensionsAttributes = [],
  }) {
    const formData = serialize(snakecaseKeys({
      course: {
        name,
        sellingPrice,
        effectiveDurationSeconds,
        vendibleTaggingsAttributes,
        passiveCourseExtensionsAttributes,
      },
    }))

    return axiosIns.put(`/waiter/courses/${id}`, formData)
  },
  deleteCourse(id) {
    return axiosIns.delete(`/waiter/courses/${id}`)
  },
  updatePositions(idAndPositions) {
    const formData = serialize(snakecaseKeys({
      courses: idAndPositions,
    }))

    return axiosIns.put('/waiter/courses/update_positions', formData)
  },
}
