import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  createItem({ name, sellingPrice, vendibleTaggingsAttributes = [] }) {
    const formData = serialize(snakecaseKeys({
      item: { name, sellingPrice, vendibleTaggingsAttributes },
    }))

    return axiosIns.post('/waiter/items', formData)
  },
  updateItem({
    id,
    name,
    sellingPrice,
    vendibleTaggingsAttributes = [],
  }) {
    const formData = serialize(snakecaseKeys({
      item: { name, sellingPrice, vendibleTaggingsAttributes },
    }))

    return axiosIns.put(`/waiter/items/${id}`, formData)
  },
  deleteItem(id) {
    return axiosIns.delete(`/waiter/items/${id}`)
  },
  updatePositions(idAndPositions) {
    const formData = serialize(snakecaseKeys({
      items: idAndPositions,
    }))

    return axiosIns.put('/waiter/items/update_positions', formData)
  },
}
